import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <>
      <div className="container-fluid fixed-top px-0">
        <nav className="navbar  navbar-expand-lg bg-white py-lg-0 px-lg-5">
          <Link to="/" className="navbar-brand">
            <img className="" src="img/pfs_logo.svg" alt="MyFamilyFintech" />
          </Link>

          <button
            type="button"
            className="navbar-toggler  me-4"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon text-dark">
              <i className="fas fa-align-justify"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto p-4 p-lg-0">
              <Link to="/" className="nav-item nav-link active">
                Home
              </Link>

              <Link to="/about" className="nav-item nav-link">
                About
              </Link>

              <Link to="/contact" className="nav-item nav-link">
                Contact
              </Link>
            </div>
            <div className="d-none d-lg-flex ms-2">
              <Link
                className="btn bg-burn py-2 px-3"
                to="https://portal.myfamilyfintech.com/"
              >
                Login
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
