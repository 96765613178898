import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
const override = css`
  display: block;
  margin: 0 auto;
  color: black;
  height: 100%;
`;

let color = "#e9511d";
export default function Loader(props) {
  return (
    <div className="page-content">
      <div className="loaderClass" style={{ textAlign: "center" }}>
        <ClipLoader
          color={color}
          loading={props.loading}
          css={override}
          size={100}
        />
      </div>
    </div>
  );
}
