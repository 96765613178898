import React, { useState, useEffect } from "react";
import Loader from "./Loader";

export default function PrivacyPolicy() {
  const [htmlResponse, setHtmlResponse] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Simulate fetching the API response
    const fetchApiResponse = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          "https://api.myfamilyfintech.com/api/GetContent/privacypolicy"
        );
        const data = await response.json();
        setLoading(false);
        setHtmlResponse(data.response);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching API:", error);
      }
    };

    fetchApiResponse();
  }, []);

  return (
    <>
      {/* <!-- Page Header Start --> */}
      <div className="container-fluid page-header">
        <div className="container">
          <h1 className="display-6 mb-2">Privacy Policy</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Privacy Policy
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}
      <div class="wrapper">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: htmlResponse }} />
        )}
      </div>
    </>
  );
}
