import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="container-fluid bg-dark text-light footer py-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <h4 className="text-white mb-4">Our Office</h4>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3"></i>6545 Preston Road
                #300, Plano,TX 75024
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3"></i>+1 214 440 3809
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3"></i>info@example.com
              </p>
            </div>
            <div className="col-lg-2 col-md-6">
              <h4 className="text-white mb-4">Services</h4>
              <Link className="btn btn-link" to="/">
                Service 1
              </Link>
              <Link className="btn btn-link" to="/">
                Service 2
              </Link>
              <Link className="btn btn-link" to="/">
                Service 3
              </Link>
              <Link className="btn btn-link" to="/">
                Service 4
              </Link>
              <Link className="btn btn-link" to="/">
                Service 5
              </Link>
            </div>
            <div className="col-lg-2 col-md-6">
              <h4 className="text-white mb-4">Quick Links</h4>
              <Link className="btn btn-link" to="/about">
                About Us
              </Link>
              <Link className="btn btn-link" to="/contact">
                Contact Us
              </Link>
              <Link className="btn btn-link" to="/terms-of-service">
                Terms of Service
              </Link>
              <Link className="btn btn-link" to="/privacy-policy">
                Privacy Policy
              </Link>
            </div>
            <div className="col-lg-4 col-md-6">
              <h4 className="text-white mb-4">Social</h4>
              <p>
                Lorem Ipsum es simplemente el texto de relleno de las imprentas
                y archivos de texto.
              </p>
              <div className="d-flex pt-2">
                <Link
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  to="/"
                >
                  <i className="fab fa-twitter"></i>
                </Link>
                <Link
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  to="/"
                >
                  <i className="fab fa-facebook-f"></i>
                </Link>
                <Link
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  to="/"
                >
                  <i className="fab fa-youtube"></i>
                </Link>
                <Link
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  to="/"
                >
                  <i className="fab fa-linkedin-in"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* copyright */}
      <div className="container-fluid py-4 footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              &copy;{" "}
              <Link className="border-bottom " to="/">
                MyFamilyFitech.com
              </Link>
              , All Right Reserved, 2023.
            </div>
          </div>
        </div>
      </div>
      {/* Back to Top */}
      <Link
        to="/"
        className="btn btn-lg btn-orange btn-lg-square rounded-circle back-to-top"
      >
        <i className="bi bi-arrow-up text-white"></i>
      </Link>
    </>
  );
}
