import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      {/* <!-- Carousel Start --> */}
      <div className="container-fluid p-0">
        <div
          id="header-carousel"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                className="w-100"
                src="img/Banner-1.jpg"
                alt="MyFamilyFintech"
              />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-start">
                    <div className="col-lg-8">
                      <h5 className="mb-0 text-dark">
                        Welcome to MyFamilyFintech
                      </h5>
                      <h1 className="display-3 text-dark mb-4">
                        Your Financial <br />
                        Status Is Our Goal
                      </h1>
                      <Link
                        to="https://portal.myfamilyfintech.com/"
                        className="btn bg-burn py-3 px-5"
                      >
                        Login
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img
                className="w-100"
                src="img/Banner-2.jpg"
                alt="MyFamilyFintech"
              />
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-start">
                    <div className="col-lg-7">
                      <h5 className="mb-0 text-dark">
                        Welcome to MyFamilyFintech
                      </h5>
                      <h1 className="display-3 mb-4 text-dark">
                        True Financial Support For You
                      </h1>
                      <Link
                        to="https://portal.myfamilyfintech.com/"
                        className="btn bg-burn py-3 px-5"
                      >
                        Login
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* <!-- Carousel End --> */}

      {/* <!-- About Start --> */}
      <div className="bg-light">
        <div className="container-xxl  py-5">
          <div className="container ">
            <div className="row g-4 align-items-end mb-4">
              <div className="col-lg-6">
                <img
                  className="img-fluid rounded"
                  src="img/about.jpg"
                  alt="MyFamilyFintech"
                />
              </div>
              <div className="col-lg-6">
                <p className="d-inline-block border rounded text-primary  fw-semi-bold py-1 px-3">
                  About Us
                </p>
                <h1 className="display-5 mb-4">
                  We Help Our Clients To Grow Their Business
                </h1>
                <p className="mb-4">
                  Lorem Ipsum es simplemente el texto de relleno de las
                  imprentas y archivos de texto. Lorem Ipsum ha sido el texto de
                  relleno estándar de las industrias desde el año 1500
                  <br /> <br />
                  Lorem Ipsum es simplemente el texto de relleno de texto.{" "}
                </p>
                <div className="border bg-white rounded p-4">
                  <nav>
                    <div
                      className="nav nav-tabs mb-3"
                      id="nav-tab"
                      role="tablist"
                    >
                      <button
                        className="nav-link fw-semi-bold active"
                        id="nav-story-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-story"
                        type="button"
                        role="tab"
                        aria-controls="nav-story"
                        aria-selected="true"
                      >
                        Story
                      </button>
                      <button
                        className="nav-link fw-semi-bold"
                        id="nav-mission-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        type="button"
                        role="tab"
                        aria-controls="nav-mission"
                        aria-selected="false"
                      >
                        Mission
                      </button>
                      <button
                        className="nav-link fw-semi-bold"
                        id="nav-vision-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-vision"
                        type="button"
                        role="tab"
                        aria-controls="nav-vision"
                        aria-selected="false"
                      >
                        Vision
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-story"
                      role="tabpanel"
                      aria-labelledby="nav-story-tab"
                    >
                      <p>
                        Lorem Ipsum es simplemente el texto de relleno de las
                        imprentas y archivos de texto. Lorem Ipsum ha sido el
                        texto de relleno estándar de las industrias desde el año
                        1500
                      </p>
                      <p className="mb-0">
                        Diam dolor diam ipsum et tempor sit. Aliqu diam amet
                        diam et eos labore. Clita erat ipsum et lorem et sit
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission-tab"
                    >
                      <p>
                        Lorem Ipsum es simplemente el texto de relleno de las
                        imprentas y archivos de texto. Lorem Ipsum ha sido el
                        texto de relleno estándar de las industrias desde el año
                        1500
                      </p>
                      <p className="mb-0">
                        Diam dolor diam ipsum et tempor sit. Aliqu diam amet
                        diam et eos labore. Clita erat ipsum et lorem et sit
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-vision"
                      role="tabpanel"
                      aria-labelledby="nav-vision-tab"
                    >
                      <p>
                        Lorem Ipsum es simplemente el texto de relleno de las
                        imprentas y archivos de texto. Lorem Ipsum ha sido el
                        texto de relleno estándar de las industrias desde el año
                        1500
                      </p>
                      <p className="mb-0">
                        Diam dolor diam ipsum et tempor sit. Aliqu diam amet
                        diam et eos labore. Clita erat ipsum et lorem et sit
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded">
              <div className="row">
                <div className="col-lg-4 ">
                  <div>
                    <div className="d-flex bg-white border p-3">
                      <div className="flex-shrink-0 btn-lg-square rounded-circle bg-burn">
                        <i className="fa fa-times text-white"></i>
                      </div>
                      <div className="ps-3">
                        <h4>Dedicated Team</h4>
                        <span>
                          Lorem Ipsum es simplemente el texto de relleno de las
                          imprentas y archivos de texto. Lorem Ipsum ha sido el
                          texto de relleno estándar de las industrias desde el
                          año 1500
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <div className="d-flex bg-white border p-3">
                      <div className="flex-shrink-0 btn-lg-square rounded-circle bg-burn">
                        <i className="fa fa-users text-white"></i>
                      </div>
                      <div className="ps-3">
                        <h4>Dedicated Team</h4>
                        <span>
                          Lorem Ipsum es simplemente el texto de relleno de las
                          imprentas y archivos de texto. Lorem Ipsum ha sido el
                          texto de relleno estándar de las industrias desde el
                          año 1500
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div>
                    <div className="d-flex bg-white border p-3">
                      <div className="flex-shrink-0 btn-lg-square rounded-circle bg-burn">
                        <i className="fa fa-phone text-white"></i>
                      </div>
                      <div className="ps-3">
                        <h4>24/7 Available</h4>
                        <span>
                          Lorem Ipsum es simplemente el texto de relleno de las
                          imprentas y archivos de texto. Lorem Ipsum ha sido el
                          texto de relleno estándar de las industrias desde el
                          año 1500
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      {/* <!-- Facts Start --> */}
      <div className="container-fluid facts">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-sm-6 col-lg-3 text-center">
              <i className="fa fa-users fa-3x text-white mb-3"></i>
              <h1 className="display-4 text-white" data-toggle="counter-up">
                1234
              </h1>
              <span className="fs-5 text-white">Happy Clients</span>
              <hr className="bg-white w-25 mx-auto mb-0" />
            </div>
            <div className="col-sm-6 col-lg-3 text-center">
              <i className="fa fa-check fa-3x text-white mb-3"></i>
              <h1 className="display-4 text-white" data-toggle="counter-up">
                1234
              </h1>
              <span className="fs-5 text-white">Projects Completed</span>
              <hr className="bg-white w-25 mx-auto mb-0" />
            </div>
            <div className="col-sm-6 col-lg-3 text-center">
              <i className="fa fa-users-cog fa-3x text-white mb-3"></i>
              <h1 className="display-4 text-white" data-toggle="counter-up">
                1234
              </h1>
              <span className="fs-5 text-white">Dedicated Staff</span>
              <hr className="bg-white w-25 mx-auto mb-0" />
            </div>
            <div className="col-sm-6 col-lg-3 text-center">
              <i className="fa fa-award fa-3x text-white mb-3"></i>
              <h1 className="display-4 text-white" data-toggle="counter-up">
                1234
              </h1>
              <span className="fs-5 text-white">Awards Achieved</span>
              <hr className="bg-white w-25 mx-auto mb-0" />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Facts End --> */}

      {/* <!-- Features Start --> */}
      <div className="container-xxl feature py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
                Why Choosing Us!
              </p>
              <h1 className="display-5 mb-4">
                Few Reasons Why People Choosing Us!
              </h1>
              <p className="mb-4 text-justify">
                Lorem Ipsum es simplemente el texto de relleno de las imprentas
                y archivos de texto.Lorem Ipsum es simplemente el texto de
                relleno de las imprentas y archivos de texto.Lorem Ipsum es
                simplemente el texto de relleno de las imprentas.
              </p>
              <a className="btn bg-burn py-3 px-5" href="/">
                Explore More
              </a>
            </div>
            <div className="col-lg-6">
              <div className="row g-4 align-items-center">
                <div className="col-md-6">
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="feature-box border rounded p-4">
                        <i className="fa fa-check fa-3x text-primary mb-3"></i>
                        <h4 className="mb-3">Fast Executions</h4>
                        <p className="mb-3">
                          Lorem Ipsum es simplemente el texto de relleno de las
                          imprentas y archivos de texto.{" "}
                        </p>
                        <a className="fw-semi-bold" href="/">
                          Read More <i className="fa fa-arrow-right ms-1"></i>
                        </a>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="feature-box border rounded p-4">
                        <i className="fa fa-check fa-3x text-primary mb-3"></i>
                        <h4 className="mb-3">Guide & Support</h4>
                        <p className="mb-3">
                          Lorem Ipsum es simplemente el texto de relleno de las
                          imprentas y archivos de texto.
                        </p>
                        <a className="fw-semi-bold" href="/">
                          Read More <i className="fa fa-arrow-right ms-1"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="feature-box border rounded p-4">
                    <i className="fa fa-check fa-3x text-primary mb-3"></i>
                    <h4 className="mb-3">Financial Secure</h4>
                    <p className="mb-3">
                      Lorem Ipsum es simplemente el texto de relleno de las
                      imprentas y archivos de texto.
                    </p>
                    <a className="fw-semi-bold" href="/">
                      Read More <i className="fa fa-arrow-right ms-1"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Features End --> */}

      {/* <!-- Service Start --> */}
      <div className="bg-light">
        <div className="container-xxl service py-5">
          <div className="container">
            <div className="text-center mx-auto">
              <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">
                Our Services
              </p>
              <h1 className="display-5 mb-5">
                Awesome Financial <br />
                Services For Business
              </h1>
            </div>
            <div className="row g-4">
              <div className="col-lg-4">
                <div className="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
                  <button
                    className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4 active"
                    data-bs-toggle="pill"
                    data-bs-target="#tab-pane-1"
                    type="button"
                  >
                    <h5 className="m-0">
                      <i className="fa fa-bars text-primary me-3"></i>Financial
                      Planning
                    </h5>
                  </button>
                  <button
                    className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
                    data-bs-toggle="pill"
                    data-bs-target="#tab-pane-2"
                    type="button"
                  >
                    <h5 className="m-0">
                      <i className="fa fa-bars text-primary me-3"></i>Cash
                      Investment
                    </h5>
                  </button>
                  <button
                    className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
                    data-bs-toggle="pill"
                    data-bs-target="#tab-pane-3"
                    type="button"
                  >
                    <h5 className="m-0">
                      <i className="fa fa-bars text-primary me-3"></i>Financial
                      Consultancy
                    </h5>
                  </button>
                  <button
                    className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-0"
                    data-bs-toggle="pill"
                    data-bs-target="#tab-pane-4"
                    type="button"
                  >
                    <h5 className="m-0">
                      <i className="fa fa-bars text-primary me-3"></i>Business
                      Loans
                    </h5>
                  </button>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="tab-content w-100">
                  <div className="tab-pane fade show active" id="tab-pane-1">
                    <div className="row g-4">
                      <div className="col-md-6 banner-height">
                        <div className="position-relative h-100">
                          <img
                            className="position-absolute image-fit rounded w-100 h-100"
                            src="img/service-1.jpg"
                            alt="MyFamilyFintech"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h3 className="mb-4">
                          25 Years Of Experience In Financial Support
                        </h3>
                        <p className="mb-4">
                          Tempor erat elitr rebum at clita. Diam dolor diam
                          ipsum sit. Aliqu diam amet diam et eos. Clita erat
                          ipsum et lorem et sit, sed stet lorem sit clita duo
                          justo erat amet.
                        </p>
                        <p>
                          <i className="fa fa-check text-primary me-3"></i>
                          Secured Loans
                        </p>
                        <p>
                          <i className="fa fa-check text-primary me-3"></i>
                          Credit Facilities
                        </p>
                        <p>
                          <i className="fa fa-check text-primary me-3"></i>Cash
                          Advanced
                        </p>
                        <a href="/" className="btn bg-burn py-3 px-5 mt-3">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="tab-pane-2">
                    <div className="row g-4">
                      <div className="col-md-6 banner-height">
                        <div className="position-relative h-100">
                          <img
                            className="position-absolute rounded image-fit w-100 h-100"
                            src="img/service-2.jpg"
                            alt="MyFamilyFintech"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h3 className="mb-4">
                          25 Years Of Experience In Financial Support
                        </h3>
                        <p className="mb-4">
                          Tempor erat elitr rebum at clita. Diam dolor diam
                          ipsum sit. Aliqu diam amet diam et eos. Clita erat
                          ipsum et lorem et sit, sed stet lorem sit clita duo
                          justo erat amet.
                        </p>
                        <p>
                          <i className="fa fa-check text-primary me-3"></i>
                          Secured Loans
                        </p>
                        <p>
                          <i className="fa fa-check text-primary me-3"></i>
                          Credit Facilities
                        </p>
                        <p>
                          <i className="fa fa-check text-primary me-3"></i>Cash
                          Advanced
                        </p>
                        <a href="/" className="btn bg-burn py-3 px-5 mt-3">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="tab-pane-3">
                    <div className="row g-4">
                      <div className="col-md-6 banner-height">
                        <div className="position-relative h-100">
                          <img
                            className="position-absolute rounded image-fit w-100 h-100"
                            src="img/service-3.jpg"
                            alt="MyFamilyFintech"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h3 className="mb-4">
                          25 Years Of Experience In Financial Support
                        </h3>
                        <p className="mb-4">
                          Tempor erat elitr rebum at clita. Diam dolor diam
                          ipsum sit. Aliqu diam amet diam et eos. Clita erat
                          ipsum et lorem et sit, sed stet lorem sit clita duo
                          justo erat amet.
                        </p>
                        <p>
                          <i className="fa fa-check text-primary me-3"></i>
                          Secured Loans
                        </p>
                        <p>
                          <i className="fa fa-check text-primary me-3"></i>
                          Credit Facilities
                        </p>
                        <p>
                          <i className="fa fa-check text-primary me-3"></i>Cash
                          Advanced
                        </p>
                        <a href="/" className="btn bg-burn py-3 px-5 mt-3">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="tab-pane-4">
                    <div className="row g-4">
                      <div className="col-md-6 banner-height">
                        <div className="position-relative h-100">
                          <img
                            className="position-absolute rounded w-100 h-100"
                            src="img/service-4.jpg image-fit"
                            alt="MyFamilyFintech"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h3 className="mb-4">
                          25 Years Of Experience In Financial Support
                        </h3>
                        <p className="mb-4">
                          Tempor erat elitr rebum at clita. Diam dolor diam
                          ipsum sit. Aliqu diam amet diam et eos. Clita erat
                          ipsum et lorem et sit, sed stet lorem sit clita duo
                          justo erat amet.
                        </p>
                        <p>
                          <i className="fa fa-check text-primary me-3"></i>
                          Secured Loans
                        </p>
                        <p>
                          <i className="fa fa-check text-primary me-3"></i>
                          Credit Facilities
                        </p>
                        <p>
                          <i className="fa fa-check text-primary me-3"></i>Cash
                          Advanced
                        </p>
                        <a href="/" className="btn btn-primary py-3 px-5 mt-3">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Service End --> */}
    </>
  );
}
